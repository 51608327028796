import React, { useEffect, useState } from "react";
import FieldDefaultForm from "./fieldDefault.form";

function ArticleForm({isEdit, req = null, onChange = null}) {
  const [data,setData] = useState(req || {})
  useEffect(() => {
    onChange && onChange(data)
  },[data])
  const onChangeTextField = obj => {
    setData(prev => ({...prev , ...obj}))
  }
  return (
    <>
      <fieldset>
        <legend>Article</legend>
        <div className="form-cont">
          <FieldDefaultForm req={req} onChange={onChangeTextField} showLabel={false} />
          {/* <Form.Group>
            {internalArticleItems.map((item, index) => (
              <Form.Group
                key={index}
                style={{ display: "flex", alignItems: "flex-end" }}
              >
                <div style={{ marginRight: "8px" }}>
                  <Form.Label> Link </Form.Label>
                  <Form.Control
                    disabled={!isEdit}
                    value={item.link}
                    onChange={(e) =>
                      onChangeInternal(e.target.value, index, "link")
                    }
                    style={{
                      marginBottom: "4px",
                      color: !isEdit ? "red" : undefined,
                    }}
                  />
                </div>
                <div style={{ marginRight: "8px" }}>
                  <Form.Label> Image </Form.Label>
                  <Form.Control
                    disabled={!isEdit}
                    value={item.image}
                    onChange={(e) =>
                      onChangeInternal(e.target.value, index, "image")
                    }
                    style={{
                      marginBottom: "4px",
                      color: !isEdit ? "red" : undefined,
                    }}
                  />
                </div>
                <div style={{ marginRight: "8px" }}>
                  <Form.Label> Tiêu đề </Form.Label>
                  <Form.Control
                    disabled={!isEdit}
                    value={item.title}
                    onChange={(e) =>
                      onChangeInternal(e.target.value, index, "title")
                    }
                    style={{
                      marginBottom: "4px",
                      color: !isEdit ? "red" : undefined,
                    }}
                  />
                </div>
                <div>
                  <Form.Label> Mô tả </Form.Label>
                  <Form.Control
                    disabled={!isEdit}
                    value={item.description}
                    onChange={(e) =>
                      onChangeInternal(e.target.value, index, "description")
                    }
                    style={{
                      marginBottom: "4px",
                      color: !isEdit ? "red" : undefined,
                    }}
                  />
                </div>
                {isEdit ? (
                  <button
                    type="button"
                    onClick={() => deleteInternal(index)}
                    className="btn btn-danger btn-icon-text"
                    style={{
                      fontSize: "10px",
                      marginLeft: "8px",
                      height: "33px",
                      display: "flex",
                      whiteSpace: "nowrap",
                      alignItems: "center",
                      marginBottom: "4px",
                    }}
                  >
                    -Xóa
                  </button>
                ) : (
                  undefined
                )}
              </Form.Group>
            ))}
            {isEdit ? (
              <button
                className="nav-link btn btn-success create-new-button no-caret"
                onClick={addNewInternal}
              >
                +Thêm
              </button>
            ) : (
              undefined
            )}
          </Form.Group> */}
        </div>
      </fieldset>
    </>
  );
}

export default ArticleForm;
