import React, { useEffect, useState } from "react";
import { Button, FormCheck } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { api } from "../../utils/api";
function PostIndex() {
  const history = useHistory();
  const [data, setData] = useState([]);
  const toggleShow = (_id) => {
    let editData = data.find(d => d._id === _id)
    if(!editData) return ;
    api({path: "/article" ,method: "POST", body: {_id, data :{show : !editData.show}}}).then(r => {
      if(r.success) {
        setData(prev => prev.map(d => {
          if(d._id === _id) d.show = !editData.show
          return d
        }))
        alert('Bài viết đã được đăng')
      } 
      else alert('Đăng bài thất bại!')
    })
  }
  useEffect(() => {
    api({ path: "/article-admin" }).then((r) => {
      if (r.success) setData(r.data);
    });
  }, []);
  return (
    <>
      <Button
        onClick={() => history.push("/post-manager")}
        className="btn btn-success"
      >
        Add New
      </Button>
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th>Index</th>
              <th>Image</th>
              <th>Title</th>
              <th>Slug</th>
              <th>Description</th>
              <th>Keywords</th>
              <th>Publish</th>
              <th>CreatedAt</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {data.map((d, i) => (
              <tr key={i}>
                <td>{i + 1}</td>
                <td>
                  {d.image ? (
                    <img src={d.image} width={40} height={40} alt="" />
                  ) : (
                    <span className="emt">No Image</span>
                  )}
                </td>
                <td>{d.title}</td>
                <td>{d.slug}</td>
                <td>{d.description}</td>
                <td>
                  {d.keywords || <span className="emt">No Keywords</span>}
                </td>
                <td>
                  <FormCheck
                    checked={d.show}
                    type="switch"
                    onChange={() => toggleShow(d._id)}
                    id={d._id}
                    label=""
                  />
                </td>
                <td>{d.createdAt}</td>
                <td>
                    <Button onClick={() =>history.push('/post-edit?id='+d._id)}>Edit</Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default PostIndex;
