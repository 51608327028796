export const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  export const editorConfig = {
    height: 300,
    menubar: false,
    plugins: [
      "advlist autolink lists link image charmap print preview anchor",
      "searchreplace visualblocks code fullscreen",
      "insertdatetime media table paste code help wordcount",
    ],
    toolbar:
      "undo redo | formatselect | fontselect | " +
      "bold italic backcolor | alignleft aligncenter " +
      "alignright alignjustify | bullist numlist outdent indent | " +
      "removeformat | help | link image",
    content_style:
      "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
    file_browser_callback_types: "image",
    file_picker_callback: function(callback, value, meta) {
      if (meta.filetype == "image") {
        var input = document.getElementById("my-file");
        input.click();
        input.onchange = function() {
          var file = input.files[0];
          var reader = new FileReader();
          reader.onload = function(e) {
            console.log("name", e.target.result);
            callback(e.target.result, {
              alt: file.name,
            });
          };
          reader.readAsDataURL(file);
        };
      }
    },
    paste_data_images: true,
  }