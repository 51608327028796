import { Editor } from "@tinymce/tinymce-react";
import React, { useEffect, useRef, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { api } from "../../utils/api";
import { editorConfig } from "../../utils/services";
import { UploadImage } from "../config/tabs/general";
function PostManager() {
  const contentRef = useRef(null);
  const [item, setItem] = useState({});
  const [slugAutoGenerate, setSlugAutoGenerate] = useState(false);
  const onChangeInternal = (value, key) => {
    setItem((prev) => ({ ...prev, [key]: value }));
  };
  const onChangeContent = (value) => {
    setItem((prev) => ({ ...prev, content: value }));
  };
  const onSave = () => {
    if(!item.title) return alert('Title required!')
    if(!item.content) return  alert('Content required!')
    api({path: '/article', method: 'POST', body: {data :item}}).then(r => {
      if(r.success) alert('Tạo bài viết thành công. Quay lại trang danh sách để đăng bài')
      else alert('Tạo bài viết thất bại')
    }).then(e => console.log(e))
  };
  const onUploadCallback = (res) => {
    console.log({ res });
    if (res.success) setItem((prev) => ({ ...prev, image: res.result.link }));
    else alert("Upload không thành công. Vui lòng thử lại");
  };
  useEffect(() => {
    if (!slugAutoGenerate) {
      let newSlug = (item.title || "")
        .toLowerCase()
        .split(/\s+/)
        .join("-");
      setItem((prev) => ({ ...prev, slug: newSlug }));
    }
  }, [item.title]);

  useEffect(() => {
    if (!item.slug) {
      let newSlug = (item.title || "")
        .toLowerCase()
        .split(/\s+/)
        .join("-");
      setItem((prev) => ({ ...prev, slug: newSlug }));
    }
  }, [item.slug]);
  return (
    <>
     
      <Form.Group>
        <Form.Group>
          <div style={{ marginRight: "8px" }}>
            <Form.Label> Slug </Form.Label>
            <Form.Control
              placeholder="Slug"
              value={item.slug}
              onKeyUp={() => setSlugAutoGenerate(true)}
              onChange={(e) => onChangeInternal(e.target.value, "slug")}
              style={{
                marginBottom: "4px",
              }}
            />
          </div>
        </Form.Group>
        <Form.Group>
          <div style={{ marginRight: "8px" }}>
            <Form.Label> Image </Form.Label>
            <UploadImage url={item.image} callback={onUploadCallback} />
          </div>
        </Form.Group>
        <Form.Group>
          <div style={{ marginRight: "8px" }}>
            <Form.Label> Title <span style={{color:'red'}}>*</span></Form.Label>
            <Form.Control
              placeholder="Enter a title"
              value={item.title}
              onChange={(e) => onChangeInternal(e.target.value, "title")}
              style={{
                marginBottom: "4px",
              }}
            />
          </div>
        </Form.Group>
        <Form.Group>
          <div style={{ marginRight: "8px" }}>
            <Form.Label> Description </Form.Label>
            <Form.Control
              value={item.description}
              placeholder="Enter description"
              onChange={(e) => onChangeInternal(e.target.value, "description")}
              style={{
                marginBottom: "4px",
              }}
            />
          </div>
        </Form.Group>
        <Form.Group>
          <div>
            <Form.Label> Content<span style={{color:'red'}}>*</span> </Form.Label>
            <Editor
              onInit={(evt, editor) => (contentRef.current = editor)}
              initialValue={item.content}
              onEditorChange={onChangeContent}
              init={editorConfig}
            />
          </div>
        </Form.Group>
        <Form.Group>
          <div style={{ marginRight: "8px" }}>
            <Form.Label> Keywords </Form.Label>
            <Form.Control
              value={item.keywords}
              placeholder="keyword1,keyword2,..."
              onChange={(e) => onChangeInternal(e.target.value, "keywords")}
              style={{
                marginBottom: "4px",
              }}
            />
          </div>
        </Form.Group>
      </Form.Group>
      <div className="break-line"></div>
      <div className="footer-btn">
        <Button
          style={{ width: "100px", height: "30px" }}
          className="btn btn-success"
          onClick={onSave}
        >
          Save
        </Button>
      </div>
    </>
  );
}

export default PostManager;
