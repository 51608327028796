import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import FieldDefaultForm from "./fieldDefault.form";
import PageDefaultForm from "./pageDefault.form";
function AchievementForm({ isEdit, req = null, onChange = null }) {
  const [data, setData] = useState(req || {});
  const [achievementItems, setAchievementItems] = useState([]);
  useEffect(() => {
    onChange && onChange({...data,achievementItems})
  },[data,achievementItems])
  useEffect(() => {
    req && req.achievementItems && setAchievementItems(req.achievementItems);
  }, [req]);
  const onChangeAchievement = (value, index , key) => {
    setAchievementItems(prev => prev.map((item, inx ) => {
      if(inx === index) item[key] = value
      return item
    }))
  };
  const deleteAchievement = (deleteIndex) => {
    setAchievementItems(prev =>  prev.filter((_, index) => index !== deleteIndex))
  };
  const addNewAchievement = () => {
    setAchievementItems((prev) => [
      ...prev,
      { number: 0, name: "", content: "" },
    ]);
  };
  const onChangeTextField = obj => {
    setData(prev => ({...prev , ...obj}))
  }
  return (
    <>
      <fieldset>
        <legend>Achievement</legend>
        <div className="form-cont">
          <FieldDefaultForm req={req} onChange={onChangeTextField} showLabel={false} />
          <Form.Label htmlFor="inputMainMenu">Achievement Items</Form.Label>
          <Form.Group id="inputMainMenu">
            {achievementItems.map((item, index) => (
              <Form.Group
                key={index}
                style={{ display: "flex", alignItems: "flex-end" }}
              >
                <div style={{ marginRight: "8px" }}>
                  <Form.Label> Number </Form.Label>
                  <Form.Control
                    disabled={!isEdit}
                    value={item.number}
                    onChange={(e) =>
                      onChangeAchievement(e.target.value, index, "number")
                    }
                    style={{
                      marginBottom: "4px",
                      color: !isEdit ? "red" : undefined,
                    }}
                  />
                </div>
                <div style={{ marginRight: "8px" }}>
                  <Form.Label> Name </Form.Label>
                  <Form.Control
                    disabled={!isEdit}
                    value={item.name}
                    onChange={(e) =>
                      onChangeAchievement(e.target.value, index, "name")
                    }
                    style={{
                      marginBottom: "4px",
                      color: !isEdit ? "red" : undefined,
                    }}
                  />
                </div>
                <div>
                  <Form.Label> Content </Form.Label>
                  <Form.Control
                    disabled={!isEdit}
                    value={item.content}
                    onChange={(e) =>
                      onChangeAchievement(e.target.value, index, "content")
                    }
                    style={{
                      marginBottom: "4px",
                      color: !isEdit ? "red" : undefined,
                    }}
                  />
                </div>
                {isEdit ? (
                  <button
                    type="button"
                    onClick={() => deleteAchievement(index)}
                    className="btn btn-danger btn-icon-text btn-delete-cus"
                  >
                    -Xóa
                  </button>
                ) : (
                  undefined
                )}
              </Form.Group>
            ))}
            {isEdit ? (
              <button
                className="nav-link btn btn-success create-new-button no-caret"
                onClick={addNewAchievement}
              >
                +Thêm
              </button>
            ) : (
              undefined
            )}
          </Form.Group>
        </div>
      </fieldset>
    </>
  );
}

export default AchievementForm;
