import React, { useEffect, useRef, useState } from "react";
import { Button, Form } from "react-bootstrap";
import "./general.css";
import { api, uploadApi } from "../../../utils/api";

export function UploadImage({
  url = "https://static.thenounproject.com/png/3674270-200.png",
  callback = null,
}) {
  const inputRef = useRef(null);
  const onFileChange = (e) => {
    let files = e.target.files;
    if (!files || !files[0]) return;
    const file = files[0];
    uploadApi(file)
      .then((result) => callback && callback({ success: true, result }))
      .catch((e) => callback && callback({ success: false, error: e }));
  };
  return (
    <div className="avatar-upload">
      <div className="avatar-edit">
        <input
          type="file"
          ref={inputRef}
          id="imageUpload"
          accept="image/*"
          onChange={onFileChange}
        />
        <img
          src="https://img.icons8.com/ios-glyphs/256/edit.png"
          width={20}
          height={20}
          onClick={() => inputRef && inputRef.current.click()}
        />
      </div>
      <div className="avatar-preview">
        <div
          id="imagePreview"
          style={{ backgroundImage: `url(${url})`, backgroundColor: "#fff" }}
        ></div>
      </div>
    </div>
  );
}

function MenuInput({ data, onChange, onDelete }) {
  const [menu, setMenu] = useState(Object.assign({}, data));
  useEffect(() => {
    onChange && onChange(menu);
  }, [menu]);
  const onChangeText = (e) => {
    const {value, name} = e.target
    setMenu((prev) => ({ ...prev, [name]: value }))
  }
  return (
    <div className="row-config">
      <div style={{ marginRight: "8px" }}>
        <Form.Label htmlFor="menu-1">Name</Form.Label>
        <Form.Control
          id="menu-1"
          value={menu ? menu.label : ""}
          placeholder="Enter a name"
          style={{ marginBottom: "4px" }}
          name="label"
          onChange={onChangeText}
        />
      </div>
      <div>
        <Form.Label htmlFor="menu-1-a">Link</Form.Label>
        <Form.Control
          id="menu-1-a"
          value={menu ? menu.href : ""}
          name="href"
          onChange={onChangeText}
          placeholder="https://..."
          style={{ marginBottom: "4px" }}
        />
      </div>
      <Button
        onClick={onDelete}
        style={{ height: "34px", marginBottom: "4px", marginLeft: "8px" }}
        className="btn btn-danger"
      >
        Xóa
      </Button>
    </div>
  );
}

function SocialInput({ link, type, onChange }) {
  const [data, setData] = useState(link || "");
  const [icon, setIcon] = useState("");
  useEffect(() => {
    onChange && onChange(data);
  }, [data]);
  useEffect(() => {
    switch (type) {
      case "facebook":
        setIcon(
          "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b8/2021_Facebook_icon.svg/2048px-2021_Facebook_icon.svg.png"
        );
        break;
      case "instagram":
        setIcon(
          "https://png.pngtree.com/png-vector/20221018/ourmid/pngtree-instagram-icon-png-image_6315974.png"
        );
        break;
      case "linkedin":
        setIcon("https://cdn-icons-png.flaticon.com/512/174/174857.png");
        break;
      case "twitter":
        setIcon(
          "https://www.iconpacks.net/icons/2/free-twitter-logo-icon-2429-thumb.png"
        );
        break;
      default:
        break;
    }
  }, []);
  const onChangeData = e => {
    const {value} = e.target
    setData(value)
  }
  return (
    <Form.Group id="inputSocial">
      <div className="row-config" style={{ marginBottom: "4px" }}>
        <img src={icon} width={34} height={34} style={{ marginRight: "8px" }} />
        <Form.Control
          value={data}
          onChange={onChangeData}
          placeholder="https://..."
          style={{ width: "calc(100% - 140px)" }}
        />
      </div>
    </Form.Group>
  );
}

function General() {
  const [config, setConfig] = useState({});
  const [headerFooter, setHeaderFooter] = useState({});
  const [mainMenu, setMainMenu] = useState([]);
  const [footerMenu, setFooterMenu] = useState([]);

  useEffect(() => {
    api({ path: "/config" }).then((r) => {
      if (r.success && r.data) setConfig(r.data[0]);
    });
    api({ path: "/header-footer" }).then((r) => {
      if (r.success && r.data) {
        setHeaderFooter(r.data[0]);
        r.data[0].mainMenu && setMainMenu(r.data[0].mainMenu);
        r.data[0].footerMenu && setFooterMenu(r.data[0].footerMenu);
      }
    });
  }, []);

  const onAddNewMenu = (type) => {
    switch (type) {
      case "mainMenu":
        setMainMenu((prev) => [...prev, { label: "", link: "" }]);
        break;
      case "footerMenu":
        setFooterMenu((prev) => [...prev, { label: "", link: "" }]);
      default:
        break;
    }
  };

  const onSave = () => {
    Promise.all([
      api({
        path: "/header-footer",
        method: "POST",
        body: {
          data: { ...headerFooter, mainMenu, footerMenu },
        },
      }),
      api({
        path: "/config",
        method: "POST",
        body: {
          data: { ...config },
        },
      }),
    ]).then((r) => {
          alert("Cập nhật thành công");
      })
      .catch((e) => {
        console.log(e);
        alert("Có lỗi xảy ra. Vui lòng thử lại");
      });
  };

  const onUploadLogo = (result, index) => {
    if (result.success)
      setConfig((prev) => ({ ...prev, [index]: result.result.link }));
  };
  const onChangeMail = (e) => {
    const {name , value } = e.target
    setConfig(prev => ({...prev , mailService: { ...(prev.mailService || {}), email: value }}))
  }
  const onChangeCopyright  = (e) => {
    const {value} = e.target
    setHeaderFooter((prev) => ({
      ...prev,
      copyrightText:value,
    }))
  }
  return (
    <>
      <div className="row-config">
        <div className="row-1">Logo</div>
        <div className="row-2">
          <UploadImage
            url={config.logo}
            key="logo"
            callback={(r) => onUploadLogo(r, "logo")}
          />
        </div>
      </div>
      <div className="row-config">
        <div className="row-1">Logo Footer</div>
        <div className="row-2">
          <UploadImage
            url={config.logoFooter}
            key="logoFooter"
            callback={(r) => onUploadLogo(r, "logoFooter")}
          />
        </div>
      </div>
      <div className="row-config">
        <div className="row-1">Copyright</div>
        <div className="row-2">
          <Form.Control
          id="copy-right"
            value={headerFooter.copyrightText || ''}
            onChange={onChangeCopyright}
            placeholder="Enter a copyright text"
            style={{ width: "50%" }}
          />
        </div>
      </div>
      <div className="row-config">
        <div className="row-1">Admin Email</div>
        <div className="row-2">
          <Form.Control
          id="mail-service"
            value={config.mailService ? config.mailService.email : ""}
            onChange={onChangeMail}
            placeholder="Enter a email"
            style={{ width: "50%" }}
          />
        </div>
      </div>
      <div className="break-line"></div>
      <div className="row-config">
        <fieldset style={{ width: "50%" }}>
          <legend>Main Menu</legend>
          {mainMenu.map((menu, i) => (
            <MenuInput
              data={menu}
              key={i}
              onDelete={(d) => {
                setMainMenu((prev) => prev.filter((_, ind) => ind !== i));
              }}
              onChange={(d) => {
                setMainMenu((prev) =>
                  prev.map((m, ind) => {
                    if (ind === i) return d;
                    return m;
                  })
                );
              }}
            />
          ))}
          <Button onClick={() => onAddNewMenu("mainMenu")}>+ Add</Button>
        </fieldset>
      </div>
      <div className="break-line"></div>
      <div className="row-config">
        <fieldset style={{ width: "50%" }}>
          <legend>Footer Menu</legend>
          {footerMenu.map((menu, i) => (
            <MenuInput
              data={menu}
              key={i}
              onDelete={(d) => {
                setFooterMenu((prev) => prev.filter((_, ind) => ind !== i));
              }}
              onChange={(d) => {
                setFooterMenu((prev) =>
                  prev.map((m, ind) => {
                    if (ind === i) return d;
                    return m;
                  })
                );
              }}
            />
          ))}
          <Button onClick={() => onAddNewMenu("footerMenu")}>+ Add</Button>
        </fieldset>
      </div>
      <div className="break-line"></div>
      <div className="row-config">
        <fieldset style={{ width: "50%" }}>
          <legend>Link Socials</legend>
          {config.socials && (
            <>
              <SocialInput
                type="facebook"
                onChange={(e) =>
                  setConfig((prev) => ({
                    ...prev,
                    socials: { ...prev.socials, facebook: e },
                  }))
                }
                link={config.socials.facebook}
              />
              <SocialInput
                type="instagram"
                onChange={(e) =>
                  setConfig((prev) => ({
                    ...prev,
                    socials: { ...prev.socials, instagram: e },
                  }))
                }
                link={config.socials.instagram}
              />
              <SocialInput
                type="linkedin"
                onChange={(e) =>
                  setConfig((prev) => ({
                    ...prev,
                    socials: { ...prev.socials, linkedin: e },
                  }))
                }
                link={config.socials.linkedin}
              />
              <SocialInput
                type="twitter"
                onChange={(e) =>
                  setConfig((prev) => ({
                    ...prev,
                    socials: { ...prev.socials, twitter: e },
                  }))
                }
                link={config.socials.twitter}
              />
            </>
          )}
        </fieldset>
      </div>
      <div className="break-line"></div>
      <div className="footer-btn">
        <Button
          style={{ width: "100px", height: "30px" }}
          className="btn btn-success"
          onClick={onSave}
        >
          Save
        </Button>
      </div>
    </>
  );
}

export default General;
