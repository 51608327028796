import { useState } from "react";
import Cookies from 'js-cookie'
export function useProvideAuth() {
    const [user, setUser] = useState(Cookies.get('auth'));
    const [useEmail, setUserEmail] = useState('')
  
    const signin = (r , cb) => {
        Cookies.set('auth', r.token)
        Cookies.set('email', r.email)
        setUser(r.token)
        setUserEmail(r.email)
        setTimeout(cb, 1000);
    };
  
    const signout = cb => {
        Cookies.remove('auth')
        setUser(null)
        setUserEmail(null)
        setTimeout(cb, 100);
    };
  
    return {
      user,
      useEmail,
      signin,
      signout
    };
}