import React, { Suspense, lazy } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Spinner from "../app/shared/Spinner";
import { useContext } from "react";
import { useProvideAuth } from "../context/auth.context";
import { createContext } from "react";
import Cookies from 'js-cookie'
import Config from "./config";
import ContactPage from "./contact";
import PageEdit from "./pages/edit.jsx";
import PostIndex from "./post";
import PostManager from "./post/manager";
import Pages from "./config/tabs/pages";
import PageCreate from "./pages/create";
import PostEdit from "./post/edit";


const Login = lazy(() => import("./user-pages/Login"));

const authContext = createContext();

function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

function useAuth() {
  return useContext(authContext);
}

function PrivateRoute({ children, ...rest }) {
  let auth = useAuth();
  console.log({ auth });
  return (
    <Route
      {...rest}
      render={({ location }) =>
        auth.user || Cookies.get('auth') ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

function AppRoutes() {
  return (
    <Suspense fallback={<Spinner />}>
      <ProvideAuth>
        <Switch>
          <Route path="/login" component={Login} />
          <PrivateRoute exact path="/config">
            <Config />
          </PrivateRoute>
          <PrivateRoute exact path="/pages">
            <Pages />
          </PrivateRoute>
          <PrivateRoute exact path="/contact">
            <ContactPage />
          </PrivateRoute>
          <PrivateRoute exact path="/post">
            <PostIndex />
          </PrivateRoute>
          <PrivateRoute exact path="/post-edit">
            <PostEdit />
          </PrivateRoute>
          <PrivateRoute exact path="/post-manager">
            <PostManager />
          </PrivateRoute>
          <PrivateRoute exact path="/page-edit">
            <PageEdit />
          </PrivateRoute>
          <PrivateRoute exact path="/page-create">
            <PageCreate />
          </PrivateRoute>
          <Redirect to="/config" /> 
        </Switch>
      </ProvideAuth>
    </Suspense>
  );
}

export default AppRoutes;
