import Cookies from 'js-cookie';

export const api = async (args) => {
    let xToken = Cookies.get('auth')
    const url = `${process.env.REACT_APP_SERVER_BASE}${process.env.REACT_APP_SERVER_VERSION}${args.path}`
    let headers = {
        "Content-Type": "application/json",
        "x-access-token": xToken,
    }
    return fetch(url, {
        method: args.method || 'GET',
        headers: headers,
        body: args.body ? JSON.stringify(args.body) : undefined
    }).then(r => r.json()).then(r => {
        if(!r || r.code === 'UNAUTHENTICATED') {
            Cookies.remove('auth')
            window.location.href = '/login'
            return ;
        } 
        return r
    })
}

export const uploadApi = async (file) => {
    var myHeaders = new Headers();
    let xToken = Cookies.get('auth')
    myHeaders.append("x-access-token", xToken);

    var formdata = new FormData();
    formdata.append("file", file, file.name);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    return fetch(`${process.env.REACT_APP_SERVER_BASE}/uploadFile`, requestOptions)
    .then(response => response.json())
    .then(r => {
        if(!r || r.code === 'UNAUTHENTICATED') {
            Cookies.remove('auth')
            window.location.href = '/login'
            return ;
        } 
        return r
    })
    .catch(error => {
        console.log('error', error)
        throw error
    });
}