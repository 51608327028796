import React, { useEffect, useState } from "react";
import LangSelect from "../../components/LangSelect";
import { api } from "../../utils/api";
const ContactPage = () => {
  const [contact, setContact] = useState([]);
  useEffect(() => {
    fetchContact();
  }, []);

  const fetchContact = () => {
    let path = "/contact" 
    api({ path }).then((r) => {
      if (r.success && r.data) setContact(r.data);
    });
  };


  return (
    <>
      {/* <LangSelect init="all" onChange={(e) => onChangeLang(e)} /> */}
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th>STT</th>
              <th>Tên</th>
              <th>Email</th>
              <th>Chủ đề</th>
              <th>Tin nhắn</th>
              <th>Thời gian gửi</th>
            </tr>
          </thead>
          <tbody>
            {contact.map((c, i) => (
              <tr key={i}>
                <td>{i + 1}</td>
                <td>{c.name}</td>
                <td>{c.email}</td>
                <td>{c.issueName}</td>
                <td>{c.message}</td>
                <td>{c.createdAt}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default ContactPage;
