import { Editor } from "@tinymce/tinymce-react";
import React, { useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import { editorConfig } from "../../utils/services";
function PageDefaultForm({ isEdit = true, showLabel = true, req = null , onChange = null }) {
  const titleRef = useRef(null);
  const descriptionRef = useRef(null)
  const [data, setData] = useState(req || {});
  useEffect(() => {
    onChange && onChange(data)
  }, [data]);
  // useEffect(() => {
  //   setData(prev => ({...prev , title: titleRef.current.getContent() , description :  descriptionRef.current.getContent()}))
  // },[titleRef.current.getContent() , descriptionRef.current.getContent()])
  const onChangeTitle =(value) => {
    setData(prev => ({...prev , title: value}))
  }
  const onChangeDescription =(value) => {
    setData(prev => ({...prev , description: value}))
  }
  const onChangeInput = (e) => {
    const { name, value } = e.target;
    setData((prev) => ({ ...prev, [name]: value }));
  };
  return (
    <>
      {req && (
        <fieldset>
          {showLabel && <legend>General</legend>}
          <Form.Group>
            <Form.Label htmlFor="main-title-default">Name</Form.Label>
            <Form.Control
              value={data.name}
              name="name"
              onChange={onChangeInput}
              placeholder="Enter a main title"
            />
          </Form.Group>
          <Form.Group>
            <Form.Label htmlFor="title-default">Title</Form.Label>
            {/* <Form.Control
              value={data.title}
              name="title"
              onChange={onChangeInput}
              placeholder="Enter a title"
            /> */}
             <Editor
                  disabled={!isEdit}
                  onInit={(evt, editor) => (titleRef.current = editor)}
                  initialValue={data.title}
                  onEditorChange={onChangeTitle}
                  init={editorConfig}
                />
          </Form.Group>
          <Form.Group>
            <Form.Label htmlFor="content-default">Description</Form.Label>
            {/* <Form.Control
              value={data.description}
              name="description"
              onChange={onChangeInput}
              placeholder="Enter content"
            /> */}
             <Editor
                  // disabled={!isEdit}
                  onInit={(evt, editor) => (descriptionRef.current = editor)}
                  initialValue={data.description}
                  onEditorChange={onChangeDescription}
                  init={editorConfig}
                />
          </Form.Group>
        </fieldset>
      )}
    </>
  );
}

export default PageDefaultForm;
