import { Editor } from "@tinymce/tinymce-react";
import React, { useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import { editorConfig } from "../../utils/services";
function FieldDefaultForm({ showLabel = true, req = null, onChange=null }) {
  const descriptionRef = useRef(null)
  const [data, setData] = useState(req || {});
  useEffect(() => {
    // console.log(data);
    onChange && onChange(data)
  }, [data]);

  const onChangeInput = (e) => {
    const { name, value } = e.target;
    setData((prev) => ({ ...prev, [name]: value }));
  };
  const onChangeDescription = (value) => {
    setData((prev) => ({ ...prev, description: value }));
  }
  return (
    <>
      {req && (
        <fieldset>
          {showLabel && <legend>General</legend>}
          <Form.Group>
            <Form.Label htmlFor="main-title-default">Name</Form.Label>
            <Form.Control
              value={data.name}
              name="name"
              onChange={onChangeInput}
              placeholder="Enter a main title"
            />
          </Form.Group>
          <Form.Group>
            <Form.Label htmlFor="title-default">Title</Form.Label>
            <Form.Control
              value={data.title}
              name="title"
              onChange={onChangeInput}
              placeholder="Enter a title"
            />
          </Form.Group>
         {['brand','achievement'].indexOf(req.type) === -1 &&  <Form.Group>
            <Form.Label htmlFor="content-default">Description</Form.Label>
            <Editor
                  onInit={(evt, editor) => (descriptionRef.current = editor)}
                  initialValue={data.description}
                  onEditorChange={onChangeDescription}
                  init={editorConfig}
                />
          </Form.Group> }
        </fieldset>
      )}
    </>
  );
}

export default FieldDefaultForm;
