import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Dropdown } from "react-bootstrap";

function LangSelect({init = 'all',  onChange = null }) {
  const [selected, setSelected] = useState(init);
  useEffect(() => {
    if (onChange && typeof onChange === "function") onChange(selected);
  }, [selected,onChange]);
  return (
    <Dropdown>
      <Dropdown.Toggle variant="btn btn-success" id="dropdownMenuButton5">
        Ngôn ngữ: {selected}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item onClick={() => setSelected("all")}>ALL</Dropdown.Item>
        <Dropdown.Item onClick={() => setSelected("vi")}>VI</Dropdown.Item>
        <Dropdown.Item onClick={() => setSelected("en")}>EN</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}
export default LangSelect;
