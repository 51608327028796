import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Button, Form, Modal, Nav } from "react-bootstrap";
import LangSelect from "../../components/LangSelect";
import { api } from "../../utils/api";
import { validateEmail } from "../../utils/services";
import General from "./tabs/general";
import Pages from "./tabs/pages";

function ConfigTab({ onSelect, defaultActiveKey = "general" }) {
  return (
    <Nav variant="tabs" defaultActiveKey={defaultActiveKey} onSelect={onSelect}>
      <Nav.Item>
        <Nav.Link eventKey="general">General</Nav.Link>
      </Nav.Item>
      {/* <Nav.Item>
        <Nav.Link eventKey="pages">Pages</Nav.Link>
      </Nav.Item> */}
    </Nav>
  );
}

function EditModal(props) {
  const [contactIssues, setContactIssues] = useState([]);
  const [social, setSocial] = useState({});
  const [email, setEmail] = useState("");
  useEffect(() => {
    console.log({ props: props.data });
    if (props.data) {
      props.data.contactIssues && setContactIssues(props.data.contactIssues);
      props.data.socials && setSocial({ ...props.data.socials });
      props.data.mailService && setEmail(props.data.mailService.email || "");
    }
  }, [props]);
  const onChangeIssue = (value, index) => {
    setContactIssues((prev) =>
      prev.map((data, idx) => {
        if (idx === index) data.title = value;
        return data;
      })
    );
  };

  const deleteIssue = (index) => {
    setContactIssues((prev) => prev.filter((_, i) => index !== i));
  };

  const onChangeSocial = (value, key) => {
    setSocial((prev) => ({ ...prev, [key]: value }));
  };

  const addNewIssue = () => {
    setContactIssues((prev) => [...prev, { title: "some title" }]);
  };

  const onSave = () => {
    if (!props.data) return;
    console.log({
      lang: props.data.lang,
      contactIssues,
      socials: social,
      mailService: {
        email,
      },
    });
    if (email && !validateEmail(email)) return alert("Email không hợp lệ!");

    api({
      path: "/config",
      method: "POST",
      body: {
        lang: props.data.lang,
        data: {
          contactIssues,
          socials: social,
          mailService: {
            email,
          },
        },
      },
    })
      .then((r) => {
        console.log({ r });
        if (r && r.success) {
          alert("Cập nhật thành công");
          props.onHide && props.onHide({ _id: props.data._id, ...r.data });
        }
      })
      .catch((e) => {
        console.log(e);
        alert("Có lỗi xảy ra. Vui lòng thử lại");
      });
  };
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Edit Config
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* language */}
        <Form.Label htmlFor="inputLanguage">Language</Form.Label>
        <Form.Control
          id="inputLanguage"
          disabled
          value={props.data.lang}
          style={{ color: "red", marginBottom: "8px" }}
        />
        {/* contact issue */}
        <Form.Label htmlFor="inputContactIssue">Contact Issue</Form.Label>
        <Form.Group id="inputContactIssue">
          {contactIssues.map((issue, index) => (
            <div style={{ display: "flex" }} key={index}>
              <Form.Control
                value={issue.title}
                onChange={(e) => onChangeIssue(e.target.value, index)}
                style={{ marginBottom: "4px" }}
              />
              <button
                type="button"
                onClick={() => deleteIssue(index)}
                className="btn btn-danger btn-icon-text"
                style={{
                  fontSize: "10px",
                  marginLeft: "8px",
                  height: "30px",
                  display: "flex",
                  whiteSpace: "nowrap",
                  alignItems: "center",
                }}
              >
                - Xóa
              </button>
            </div>
          ))}
          <button
            className="nav-link btn btn-success create-new-button no-caret"
            onClick={addNewIssue}
          >
            + Thêm
          </button>
        </Form.Group>
        {/* social */}
        <Form.Label htmlFor="inputSocial">Socials</Form.Label>
        <Form.Group id="inputSocial">
          <Form.Control
            value={social.facebook}
            onChange={(e) => onChangeSocial(e.target.value, "facebook")}
            style={{ marginBottom: "4px" }}
          />
          <Form.Control
            value={social.twitter}
            onChange={(e) => onChangeSocial(e.target.value, "twitter")}
            style={{ marginBottom: "4px" }}
          />
          <Form.Control
            value={social.instagram}
            onChange={(e) => onChangeSocial(e.target.value, "instagram")}
            style={{ marginBottom: "4px" }}
          />
          <Form.Control
            value={social.linkedin}
            onChange={(e) => onChangeSocial(e.target.value, "linkedin")}
            style={{ marginBottom: "4px" }}
          />
        </Form.Group>
        {/* mail services */}

        <Form.Label htmlFor="inputMailService">Mail Receive</Form.Label>
        <Form.Group id="inputMailService">
          <Form.Control
            value={email}
            type="email"
            onChange={(e) => setEmail(e.target.value)}
            style={{ marginBottom: "4px" }}
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-success btn-fw"
          onClick={onSave}
        >
          Save
        </button>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

function Config() {
  const [tab, setTab] = useState("general");
  return (
    <>
      <ConfigTab defaultActiveKey={tab} onSelect={(e) => setTab(e)} />
      <div className="tab-container">
        {tab === "general" && <General />}
        {tab === "pages" && <Pages />}
      </div>
    </>
  );
}

// function Config() {
//   const [configs, setConfigs] = useState([]);
//   const [showModal, setShowModal] = useState(false);
//   const [editing, setEditing] = useState({});
//   useEffect(() => {
//     fetchConfig("all");
//   }, []);
//   const fetchConfig = (lang) => {
//     let path = lang == "all" ? "/config" : `/config/${lang}`;

//     api({ path }).then((r) => {
//       console.log({ 1: r.data });
//       if (r.success) setConfigs(r.data);
//     });
//   };
//   const onChangeLang = (lang) => {
//     fetchConfig(lang);
//   };

//   const onHideEdit = (updated) => {
//     setShowModal(false);
//     if (updated && editing) {
//       setConfigs((prev) =>
//         prev.map((config) => {
//           if (config._id == updated._id) config = { ...config, ...updated };
//           return config;
//         })
//       );
//     }
//   };
//   return (
//     <>
//       <LangSelect init="all" onChange={(e) => onChangeLang(e)} />
//       <div className="table-responsive">
//         <table className="table">
//           <thead>
//             <tr>
//               <th>index</th>
//               <th>Language</th>
//               <th>Contact Issues</th>
//               <th>socials</th>
//               <th>Mail Receive</th>
//               <th>Actions</th>
//             </tr>
//           </thead>
//           {configs.map((config, index) => (
//             <tbody key={index}>
//               <tr>
//                 <td>{index + 1}</td>
//                 <td>{config.lang}</td>
//                 <td>
//                   {(config.contactIssues || []).map((issue, index) => (
//                     <li key={index}>{issue.title}</li>
//                   ))}
//                 </td>
//                 <td>
//                   <p>Facebook : {config.socials.facebook}</p>
//                   <p>Twitter : {config.socials.twitter}</p>
//                   <p>Instagram : {config.socials.instagram}</p>
//                   <p>Linkedin : {config.socials.linkedin}</p>
//                 </td>
//                 <td>{config.mailService ? config.mailService.email : ""}</td>
//                 <td>
//                   <button
//                     type="button"
//                     className="btn btn-outline-secondary btn-icon-text"
//                     onClick={() => {
//                       setEditing(config);
//                       setShowModal(true);
//                     }}
//                   >
//                     Edit
//                     <i className="mdi mdi-file-check btn-icon-append"></i>
//                   </button>
//                 </td>
//               </tr>
//             </tbody>
//           ))}
//         </table>
//         <EditModal
//           show={showModal}
//           onHide={(updated) => onHideEdit(updated)}
//           data={editing}
//         />
//       </div>
//     </>
//   );
// }
export default Config;
