import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { uploadApi } from "../../utils/api";
import FieldDefaultForm from "./fieldDefault.form";
import PageDefaultForm from "./pageDefault.form";
function CareerForm({isEdit, req = null , onChange = null}) {
  const [data, setData] = useState(req ? req.careerData : {});
  const [items, setItems] = useState([]);
  useEffect(() => {
    console.log(123,req)
    if(req && req.careerData){
      req.careerData.items && setItems(req.careerData.items)
    }
  }, [req])
  
  const addNewCareerItem = () => {
    setItems(prev => [...prev , {icon: '',name :'',description: ''}])
  }
  const onChangeCareer = () => {}
  const onChangeCareerItem = (value , index , key) => {
    setItems(prev => prev.map((item, i) => {
      if(i === index) item[key] = value
      return item
    }))
  }
  const deleteCareerItem = (deleteIndex) => {
    setItems(prev => prev.filter((item, index) => deleteIndex !== index))
  }
  const onChangeTextField =(obj) => {
    setData(prev =>({...prev , ...obj}))
  }
  const onChangeFile = (e, index) => {
    let files = e.target.files;
    if (!files || !files[0]) return;
    const file = files[0];
    uploadApi(file)
      .then((result) => {
        if (result) {
          // setImages((prev) =>
          //   prev.map((img, i) => {
          //     if (i === index) img.url = result.link;
          //     return img;
          //   })
          // );
          onChangeCareerItem(result.link , index, 'icon')
        }
      })
      .catch((e) => {
        console.log("Upload error", e);
      });
  };
  return (
    <>
      <fieldset>
        <legend>Career</legend>
        <div className="form-cont">
          <FieldDefaultForm showLabel={false} req={req} onChange={onChangeTextField} />
          <Form.Label htmlFor="inputMailService">Name [For Detail]</Form.Label>
          <Form.Group id="inputMailService">
            <Form.Control
              value={data.title}
              disabled={!isEdit}
              onChange={(e) => onChangeCareer(e.target.value, "title")}
              style={{
                marginBottom: "4px",
                color: !isEdit ? "red" : undefined,
              }}
            />
          </Form.Group>
          <Form.Label htmlFor="inputMailService">Description [For Detail] </Form.Label>
          <Form.Group id="inputMailService">
            <Form.Control
              value={data.description}
              disabled={!isEdit}
              onChange={(e) => onChangeCareer(e.target.value, "description")}
              style={{
                marginBottom: "4px",
                color: !isEdit ? "red" : undefined,
              }}
            />
          </Form.Group>
          <Form.Label htmlFor="inputMainMenu"> Career Items </Form.Label>
          <Form.Group id="inputMainMenu">
            {(items || []).map((item, index) => (
              <Form.Group
                key={index}
                style={{ display: "flex", alignItems: "flex-end" }}
              >
                 <div style={{ marginRight: "8px" }}>
                  {/* <Form.Label> Upload </Form.Label> */}
                  <label
                    className="label-file"
                    htmlFor={"input-image-career-" + index}
                  >
                    <img
                      width={20}
                      height={20}
                      src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/6b/OOjs_UI_icon_upload.svg/1200px-OOjs_UI_icon_upload.svg.png"
                      alt=""
                    />
                  </label>
                  <input
                    id={"input-image-career-" + index}
                    type="file"
                    accept="image/*"
                    onChange={(e) => onChangeFile(e, index)}
                    style={{ display: "none" }}
                  />
                </div>
                <div style={{ marginRight: "8px" }}>
                  <Form.Label> Icon </Form.Label>
                  <Form.Control
                    disabled={!isEdit}
                    value={item.icon}
                    onChange={(e) =>
                      onChangeCareerItem(e.target.value, index, "icon")
                    }
                    style={{
                      marginBottom: "4px",
                      color: !isEdit ? "red" : undefined,
                    }}
                  />
                </div>
                <div style={{ marginRight: "8px" }}>
                  <Form.Label> Tên </Form.Label>
                  <Form.Control
                    disabled={!isEdit}
                    value={item.name}
                    onChange={(e) =>
                      onChangeCareerItem(e.target.value, index, "name")
                    }
                    style={{
                      marginBottom: "4px",
                      color: !isEdit ? "red" : undefined,
                    }}
                  />
                </div>
                <div style={{ marginRight: "8px" }}>
                  <Form.Label> Mô tả </Form.Label>
                  <Form.Control
                    disabled={!isEdit}
                    value={item.description}
                    onChange={(e) =>
                      onChangeCareerItem(e.target.value, index, "description")
                    }
                    style={{
                      marginBottom: "4px",
                      color: !isEdit ? "red" : undefined,
                    }}
                  />
                </div>
                {isEdit ? (
                  <button
                    type="button"
                    onClick={() => deleteCareerItem(index)}
                    className="btn btn-danger btn-icon-text"
                    style={{
                      fontSize: "10px",
                      marginLeft: "8px",
                      height: "33px",
                      display: "flex",
                      whiteSpace: "nowrap",
                      alignItems: "center",
                      marginBottom: "4px",
                    }}
                  >
                    -Xóa
                  </button>
                ) : (
                  undefined
                )}
              </Form.Group>
            ))}
            {isEdit ? (
              <button
                className="nav-link btn btn-success create-new-button no-caret"
                onClick={addNewCareerItem}
              >
                +Thêm
              </button>
            ) : (
              undefined
            )}
          </Form.Group>
        </div>
      </fieldset>
    </>
  );
}

export default CareerForm;
