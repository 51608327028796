import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { uploadApi } from "../../utils/api";
import FieldDefaultForm from "./fieldDefault.form";

function BrandForm({ isEdit, req = null, onChange = null, key }) {
  const [data, setData] = useState(req || {});
  const [images, setImages] = useState([]);
  useEffect(() => {
    onChange && onChange({ ...data, images });
  }, [data, images]);
  useEffect(() => {
    req && req.images && setImages(req.images);
  }, [req]);
  const onChangeImage = (value, index, key) => {
    setImages((prev) =>
      prev.map((image, inx) => {
        if (inx === index) image[key] = value;
        return image;
      })
    );
  };
  const deleteImage = (deleteIndex) => {
    setImages((prev) => prev.filter((_, index) => index !== deleteIndex));
  };
  const addNewImage = () => {
    setImages((prev) => [...prev, { url: "", href: "" }]);
  };
  const onChangeTextField = (obj) => {
    setData((prev) => ({ ...prev, ...obj }));
  };
  const onChangeFile = (e, index) => {
    let files = e.target.files;
    if (!files || !files[0]) return;
    const file = files[0];
    uploadApi(file)
      .then((result) => {
        if (result) {
          setImages((prev) =>
            prev.map((img, i) => {
              if (i === index) img.url = result.link;
              return img;
            })
          );
        }
      })
      .catch((e) => {
        console.log("Upload error", e);
      });
  };
  return (
    <>
      <fieldset>
        <legend>Brands</legend>
        <div className="form-cont">
          <FieldDefaultForm
            req={req}
            onChange={onChangeTextField}
            showLabel={false}
          />
          <Form.Group className="form-group-cus">
            {images.map((img, index) => (
              <Form.Group key={index} style={{ display: "flex" }}>
                <div style={{ marginRight: "8px" }}>
                  {/* <Form.Label> Upload </Form.Label> */}
                  <label
                    className="label-file"
                    htmlFor={"input-image-brand-" + index}
                  >
                    <img
                      width={20}
                      height={20}
                      src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/6b/OOjs_UI_icon_upload.svg/1200px-OOjs_UI_icon_upload.svg.png"
                      alt=""
                    />
                  </label>
                  <input
                    id={"input-image-brand-" + index}
                    type="file"
                    accept="image/*"
                    onChange={(e) => onChangeFile(e, index)}
                    style={{ display: "none" }}
                  />
                </div>
                <div style={{ marginRight: "8px" }}>
                  <Form.Label> URL </Form.Label>
                  <Form.Control
                    disabled={!isEdit}
                    value={img.url}
                    onChange={(e) =>
                      onChangeImage(e.target.value, index, "url")
                    }
                    style={{
                      marginBottom: "4px",
                      color: !isEdit ? "red" : undefined,
                    }}
                  />
                </div>
                <div style={{ marginRight: "8px" }}>
                  <Form.Label> ALT </Form.Label>
                  <Form.Control
                    disabled={!isEdit}
                    value={img.alt}
                    onChange={(e) =>
                      onChangeImage(e.target.value, index, "alt")
                    }
                    style={{
                      marginBottom: "4px",
                      color: !isEdit ? "red" : undefined,
                    }}
                  />
                </div>
                <div>
                  <Form.Label> Href </Form.Label>
                  <Form.Control
                    disabled={!isEdit}
                    value={img.href}
                    onChange={(e) =>
                      onChangeImage(e.target.value, index, "href")
                    }
                    style={{
                      marginBottom: "4px",
                      color: !isEdit ? "red" : undefined,
                    }}
                  />
                </div>
                {isEdit ? (
                  <button
                    type="button"
                    onClick={() => deleteImage(index)}
                    className="btn btn-danger btn-icon-text btn-delete-cus"
                  >
                    -Xóa
                  </button>
                ) : (
                  undefined
                )}
              </Form.Group>
            ))}
            {isEdit ? (
              <button
                className="nav-link btn btn-success create-new-button no-caret"
                onClick={addNewImage}
              >
                +Thêm
              </button>
            ) : (
              undefined
            )}
          </Form.Group>
        </div>
      </fieldset>
    </>
  );
}

export default BrandForm;
