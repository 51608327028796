import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import FieldDefaultForm from "./fieldDefault.form";
import PageDefaultForm from "./pageDefault.form";

function LocationForm({ isEdit, req = null, onChange = null }) {
  const [officeItems, setOfficeItems] = useState([]);
  const [data, setData] = useState(req || {});
  useEffect(() => {
    if (req) {
      setOfficeItems(req.officeItems);
    }
  }, [req]);
  useEffect(() => {
    onChange && onChange({...data,officeItems})
  },[data,officeItems])
  const addNewOffice = () => {
    setOfficeItems((prev) => [
      ...prev,
      { country: "", other: "", fullAddress: "", link: "" },
    ]);
  };
  const onChangeOffice = (value, index ,key) => {
    setOfficeItems(prev => prev.map((item, idx) => {
      if(idx === index) item[key] = value
      return item
    }))
  };
  const deleteOffice = (deleteIndex) => {
    setOfficeItems((prev) => prev.filter((item, index) => index !== deleteIndex));
  };
  const onChangeTextField = (obj) => {
    setData(prev => ({...prev , name: obj.name, title: obj.title, description: obj.description}))
  };
  return (
    <>
      <fieldset>
        <legend>Location</legend>
        <div className="form-cont">
          <FieldDefaultForm
            req={req}
            onChange={onChangeTextField}
            showLabel={false}
          />
          <Form.Group className="form-group-cus">
            {officeItems.map((item, index) => (
              <Form.Group
                key={index}
                style={{ display: "flex", alignItems: "flex-end" }}
              >
                <div style={{ marginRight: "8px" }}>
                  <Form.Label> Country </Form.Label>
                  <Form.Control
                    disabled={!isEdit}
                    value={item.country}
                    onChange={(e) =>
                      onChangeOffice(e.target.value, index, "country")
                    }
                    style={{
                      marginBottom: "4px",
                      color: !isEdit ? "red" : undefined,
                    }}
                  />
                </div>
                <div style={{ marginRight: "8px" }}>
                  <Form.Label> Other </Form.Label>
                  <Form.Control
                    disabled={!isEdit}
                    value={item.other}
                    onChange={(e) =>
                      onChangeOffice(e.target.value, index, "other")
                    }
                    style={{
                      marginBottom: "4px",
                      color: !isEdit ? "red" : undefined,
                    }}
                  />
                </div>
                <div style={{ marginRight: "8px" }}>
                  <Form.Label> Full Address </Form.Label>
                  <Form.Control
                    disabled={!isEdit}
                    value={item.fullAddress}
                    onChange={(e) =>
                      onChangeOffice(e.target.value, index, "fullAddress")
                    }
                    style={{
                      marginBottom: "4px",
                      color: !isEdit ? "red" : undefined,
                    }}
                  />
                </div>
                <div>
                  <Form.Label> Link </Form.Label>
                  <Form.Control
                    disabled={!isEdit}
                    value={item.link}
                    onChange={(e) =>
                      onChangeOffice(e.target.value, index, "link")
                    }
                    style={{ marginBottom: "4px" }}
                  />
                </div>
                {isEdit ? (
                  <button
                    type="button"
                    onClick={() => deleteOffice(index)}
                    className="btn btn-danger btn-icon-text btn-delete-cus"
                  >
                    -Xóa
                  </button>
                ) : (
                  undefined
                )}
              </Form.Group>
            ))}
            {isEdit ? (
              <button
                className="nav-link btn btn-success create-new-button no-caret"
                onClick={addNewOffice}
              >
                +Thêm
              </button>
            ) : (
              undefined
            )}
          </Form.Group>
        </div>
      </fieldset>
    </>
  );
}

export default LocationForm;
