import React, { useEffect, useState } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import AchievementForm from "../../components/form/achievement.form";
import ArticleForm from "../../components/form/article.form";
import BrandForm from "../../components/form/brand.form";
import CareerForm from "../../components/form/career.form";
import JobForm from "../../components/form/job.form";
import LocationForm from "../../components/form/location.form";
import MissionVissionForm from "../../components/form/missionVission.form";
import PageDefaultForm from "../../components/form/pageDefault.form";
import TeamForm from "../../components/form/team.form";
import useQuery from "../../context/router.context";
import { api, uploadApi } from "../../utils/api";

function PageEdit() {
  const history = useQuery();
  const [data, setData] = useState(null);
  const [sections, setSections] = useState([]);
  const [sectionEdit, setSectionEdit] = useState({});
  const [subImage, setSubImage] = useState([])
  const onSave = () => {
    console.log({ ...data, sections: sectionEdit });
    let dataSave = { ...data, sections: sectionEdit }
    if(data.banner) {
      dataSave['banner'] = {
        ...dataSave,
        subImages: subImage
      }
    }
    api({
      path: "/pages",
      method: "POST",
      body: {
        _id: history.get("_id"),
        data: dataSave
      },
    })
      .then((r) => {
        if (r.success) alert("Update thành công");
        else alert("Update thất bại");
      })
      .catch((e) => {
        console.log(e);
      });
  };
  useEffect(() => {
    console.log("history", history.get("_id"));
    api({ path: `/pages/${history.get("_id")}` }).then((r) => {
      console.log({ r: r.data.sections });
      if (r.success && r.data) {
        setData(r.data);
        if (r.data.sections) {
          setSections(r.data.sections);
          setSectionEdit(r.data.sections);
         
        }
        r.data.banner && r.data.banner.subImages && setSubImage(r.data.banner.subImages)
      }
    });
  }, [history.get("_id")]);

  const onChangeForm = (obj) => {
    setData((prev) => ({ ...prev, ...obj }));
  };
  const onChangeSection = (_id, obj) => {
    setSectionEdit((prev) =>
      prev.map((section) => {
        if (section._id === _id) return { _id, ...obj };
        return section;
      })
    );
  };
  const onChangePath = (e) => {
    const { value } = e.target;
    setData((prev) => ({ ...prev, path: value }));
  };
  const onChangeBanner = (value) => {
    setData(prev => ({...prev , banner : {...prev.banner , image :value}}))
  }
  const onChangeFile = (e) => {
    let files = e.target.files;
    if (!files || !files[0]) return;
    const file = files[0];
    uploadApi(file)
      .then((result) => {
        if (result) {
          onChangeBanner(result.link)
        }
      })
      .catch((e) => {
        console.log("Upload error", e);
      });
  };
  const onChangeFileSubBanner = (e,index) => {
    let files = e.target.files;
    if (!files || !files[0]) return;
    const file = files[0];
    uploadApi(file)
      .then((result) => {
        if (result) {
          setSubImage(prev => prev.map((img, i) => {
            if(index === i) img['url'] = result.link
            return img
          }))
        }
      })
      .catch((e) => {
        console.log("Upload error", e);
      });
  }
  const onChangeImage = (value, index, key) => {
    setSubImage(prev => prev.map((img, i) => {
      if(index === i) img[key] = value
      return img
    }))
  }

  const addNewImage = () => {
    setSubImage(prev => [...prev ,{url: '', alt: '', link: '' }])
  }
  const deleteImage = (index) => {
    setSubImage(prev => prev.filter((_, i) => i !== index))
  }
  return (
    <>
      {data ? (
        <>
          <PageDefaultForm
            isEdit
            req={{
              name: data.name,
              title: data.title,
              description: data.description,
            }}
            onChange={onChangeForm}
          />
          {data.canDelete && (
            <Form.Group>
              <Form.Label htmlFor="main-title-default">Path</Form.Label>
              <Form.Control
                value={data.path}
                name="path"
                onChange={onChangePath}
                placeholder="Enter a path. example: /home-second"
              />
            </Form.Group>
          )}

         {data.banner  && <>
         <Form.Group style={{ display: "flex", alignItems: "flex-end" }}>
            <div style={{ marginRight: "8px" }}>
              {/* <Form.Label> Upload </Form.Label> */}
              <label
                className="label-file"
                htmlFor={"input-image-banner-"}
              >
                <img
                  width={20}
                  height={20}
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/6b/OOjs_UI_icon_upload.svg/1200px-OOjs_UI_icon_upload.svg.png"
                  alt=""
                />
              </label>
              <input
                id={"input-image-banner-"}
                type="file"
                accept="image/*"
                onChange={(e) => onChangeFile(e)}
                style={{ display: "none" }}
              />
            </div>
            <div style={{ marginRight: "8px" }}>
              <Form.Label> Banner Image </Form.Label>
              <Form.Control
               placeholder="https://..."
                value={data.banner.image}
                onChange={(e) =>
                  onChangeBanner(e.target.value)
                }
                style={{
                  marginBottom: "4px",
                }}
              />
            </div>
          </Form.Group> 
          <Form.Label htmlFor="inputMainMenu">Sub Banner Image</Form.Label>
          <Form.Group id="inputMainMenu" className="form-group-cus">
            {subImage.map((img, index) => (
              <Form.Group key={index} style={{ display: "flex" }}>
                <div style={{ marginRight: "8px" }}>
                  {/* <Form.Label> Upload </Form.Label> */}
                  <label
                    className="label-file"
                    htmlFor={"input-image-sub-" + index}
                  >
                    <img
                      width={20}
                      height={20}
                      src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/6b/OOjs_UI_icon_upload.svg/1200px-OOjs_UI_icon_upload.svg.png"
                      alt=""
                    />
                  </label>
                  <input
                    id={"input-image-sub-" + index}
                    type="file"
                    accept="image/*"
                    onChange={(e) => onChangeFileSubBanner(e, index)}
                    style={{ display: "none" }}
                  />
                </div>
                <div style={{ marginRight: "8px" }}>
                  <Form.Label> URL </Form.Label>
                  <Form.Control
                    value={img.url}
                    placeholder="https://..."
                    onChange={(e) =>
                      onChangeImage(e.target.value, index, "url")
                    }
                    style={{
                      marginBottom: "4px",
                    }}
                  />
                </div>
                <div style={{ marginRight: "8px" }}>
                  <Form.Label> ALT </Form.Label>
                  <Form.Control
                    value={img.alt}
                    onChange={(e) =>
                      onChangeImage(e.target.value, index, "alt")
                    }
                    style={{
                      marginBottom: "4px",
                    }}
                  />
                </div>
                <div>
                  <Form.Label> Href </Form.Label>
                  <Form.Control
                    value={img.link}
                    placeholder="https://..."
                    onChange={(e) =>
                      onChangeImage(e.target.value, index, "link")
                    }
                    style={{
                      marginBottom: "4px",
                    }}
                  />
                </div>
                  <button
                    type="button"
                    onClick={() => deleteImage(index)}
                    className="btn btn-danger btn-icon-text btn-delete-cus"
                  >
                    -Xóa
                  </button>
              </Form.Group>
            ))}
              <button
                className="nav-link btn btn-success create-new-button no-caret"
                onClick={addNewImage}
              >
                +Thêm
              </button>
           
          </Form.Group>
          </>
}
          {sections.map((section, index) => {
            switch (section.type) {
              case "team":
                return (
                  <TeamForm
                    key={index}
                    onChange={(e) => onChangeSection(section._id, e)}
                    req={section}
                    isEdit
                  />
                );
              case "location":
                return (
                  <LocationForm
                    key={index}
                    onChange={(e) => onChangeSection(section._id, e)}
                    req={section}
                    isEdit
                  />
                );
              case "career":
                return (
                  <CareerForm
                    key={index}
                    onChange={(e) => onChangeSection(section._id, e)}
                    req={section}
                    isEdit
                  />
                );
              case "mission":
              case "vission":
                return (
                  <MissionVissionForm
                    key={index}
                    onChange={(e) => onChangeSection(section._id, e)}
                    req={section}
                    isEdit
                  />
                );
              case "brand":
                return (
                  <BrandForm
                    key={index}
                    onChange={(e) => onChangeSection(section._id, e)}
                    req={section}
                    isEdit
                  />
                );
              case "achievement":
                return (
                  <AchievementForm
                    key={index}
                    onChange={(e) => onChangeSection(section._id, e)}
                    req={section}
                    isEdit
                  />
                );
              case "job":
                return (
                  <JobForm
                    key={index}
                    onChange={(e) => onChangeSection(section._id, e)}
                    req={section}
                    isEdit
                  />
                );
              case "internal_article":
                return (
                  <ArticleForm
                    key={index}
                    onChange={(e) => onChangeSection(section._id, e)}
                    req={section}
                    isEdit
                  />
                );
              default:
                return <></>;
            }
          })}
          <div className="break-line"></div>
          <div className="footer-btn">
            <Button
              style={{ width: "100px", height: "30px" }}
              className="btn btn-success"
              onClick={onSave}
            >
              Save
            </Button>
          </div>
        </>
      ) : (
        <Spinner animation="border" variant="success" />
      )}
    </>
  );
}

export default PageEdit;
