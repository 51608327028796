import { Editor } from "@tinymce/tinymce-react";
import React, { useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import { editorConfig } from "../../utils/services";
import FieldDefaultForm from "./fieldDefault.form";

function JobForm({ isEdit, req = null, onChange = null }) {
  const benefitsRef = useRef(null);
  const responsibilityRef = useRef(null);
  const introductionRef = useRef(null);
  const [job, setJob] = useState(req ? req.jobData : {});
  const [items, setItems] = useState([]);
  useEffect(() => {
    if(!benefitsRef.current || !responsibilityRef.current || !introductionRef.current  ) return
    setJob((prev) => ({
      ...prev,
      benefits: benefitsRef.current.getContent(),
      responsibility: responsibilityRef.current.getContent(),
      introduction: introductionRef.current.getContent(),
    }));
  }, [
    benefitsRef.current && benefitsRef.current.getContent(),
    responsibilityRef.current && responsibilityRef.current.getContent(),
    introductionRef.current && introductionRef.current.getContent(),
  ]);
  useEffect(() => {
    req && req.jobData && req.jobData.items && setItems(req.jobData.items);
  }, [req]);
  const onChangeJobItem = (value, index, key) => {
    setItems((prev) =>
      prev.map((item, ind) => {
        if (ind === index) item[key] = value;
        return item;
      })
    );
  };
  const deleteJobItem = (deleteIndex) => {
    setItems((prev) => prev.filter((_, index) => index !== deleteIndex));
  };
  const addNewJobItem = () => {
    setItems((prev) => [...prev, { title: "", description: "" }]);
  };
  const onEditorChange = (value, key) => {
    setJob((prev) => ({
      ...prev,
      [key]: value
    }));
  }

  useEffect(() => {
    console.log({job})
    onChange && onChange({...job, items})
  },[job , items])
  return (
    <>
      <fieldset>
        <legend>Jobs</legend>
        <div className="form-cont">
          <FieldDefaultForm showLabel={false} />
          <Form.Label htmlFor="inputMailService"> Benefits </Form.Label>
          <Form.Group id="inputMailService">
            <Editor
              disabled={!isEdit}
              onInit={(evt, editor) => (benefitsRef.current = editor)}
              initialValue={job.benefits}
              onEditorChange={(content) => onEditorChange(content, 'benefits')}
              init={editorConfig}
            />
          </Form.Group>
          <Form.Label htmlFor="inputMailService">Responsibility</Form.Label>
          <Form.Group id="inputMailService">
            <Editor
              disabled={!isEdit}
              onInit={(evt, editor) => (responsibilityRef.current = editor)}
              initialValue={job.responsibility}
              init={editorConfig}
            />
          </Form.Group>
          <Form.Label htmlFor="inputMailService"> Introduction </Form.Label>
          <Form.Group id="inputMailService">
            <Editor
              disabled={!isEdit}
              onInit={(evt, editor) => (introductionRef.current = editor)}
              initialValue={job.introduction}
              init={editorConfig}
            />
          </Form.Group>
          <Form.Label htmlFor="inputMainMenu"> Job Items </Form.Label>
          <Form.Group id="inputMainMenu" className="form-group-cus">
            {(items || []).map((item, index) => (
              <Form.Group
                key={index}
                style={{ display: "flex", alignItems: "flex-end" }}
              >
                <div style={{ marginRight: "8px" }}>
                  <Form.Label> Tiêu đề </Form.Label>
                  <Form.Control
                    disabled={!isEdit}
                    value={item.title}
                    onChange={(e) =>
                      onChangeJobItem(e.target.value, index, "title")
                    }
                    style={{
                      marginBottom: "4px",
                      color: !isEdit ? "red" : undefined,
                    }}
                  />
                </div>
                <div style={{ marginRight: "8px" }}>
                  <Form.Label> Mô tả </Form.Label>
                  <Form.Control
                    disabled={!isEdit}
                    value={item.description}
                    onChange={(e) =>
                      onChangeJobItem(e.target.value, index, "description")
                    }
                    style={{
                      marginBottom: "4px",
                      color: !isEdit ? "red" : undefined,
                    }}
                  />
                </div>
                {isEdit ? (
                  <button
                    type="button"
                    onClick={() => deleteJobItem(index)}
                    className="btn btn-danger btn-icon-text btn-delete-cus"
                  >
                    -Xóa
                  </button>
                ) : (
                  undefined
                )}
              </Form.Group>
            ))}
            {isEdit ? (
              <button
                className="nav-link btn btn-success create-new-button no-caret"
                onClick={addNewJobItem}
              >
                +Thêm
              </button>
            ) : (
              undefined
            )}
          </Form.Group>
        </div>
      </fieldset>
    </>
  );
}

export default JobForm;
