import React, { useEffect, useState } from "react";
import { Button, Dropdown, Form, FormCheck } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { api } from "../../../utils/api";
import "./pages.css";
function Pages() {
  const [page, setPage] = useState([]);
  const [template, setTemplate] = useState([])
  const router = useHistory()
  useEffect(() => {
    api({ path: "/pages" }).then((r) => {
      if (r.success && r.data) setPage(r.data);
    });

    api({path: "/page-template"}).then(r => {
      if(r.success && r.data) setTemplate(r.data)
    })
  }, []);

  const onSave = () => {
    console.log(page)
  }
  const toCreatePage = (type) => {
    router.push(`/page-create?template=${type}`)
    console.log('creating',type)
  }
  return (
    <div className="pages-container">
      <Dropdown>
        <Dropdown.Toggle variant="success" id="dropdown-basic">
          Create Page
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {template.map((tmp, index) =>  <Dropdown.Item key={index} onClick={() => toCreatePage(tmp.type)}>{tmp.name}</Dropdown.Item>)}
        </Dropdown.Menu>
      </Dropdown>
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Title</th>
              <th>Path</th>
              {/* <th>Active</th> */}
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {page.map((p, i) => (
              <tr key={i}>
                <td>{p.name}</td>
                <td>{p.title || <span className="emt">No Title</span>}</td>
                <td>{p.path} </td>
                {/* <td>
                  <FormCheck
                    checked={p.show}
                    type="switch"
                    onChange={(e) =>
                      setPage(prev =>
                        prev.map((d) => {
                          if (d._id === p._id) d.show = !d.show;
                          return d;
                        })
                      )
                    }
                    id={p._id}
                    label=""
                  />
                </td> */}
                <td>
                  <Button style={{marginRight: '4px'}} onClick={() => router.push(`/page-edit?_id=${p._id}`)}>Edit</Button>
                  { p.canDelete && <Button className="btn btn-danger" onClick={() => router.push(`/page-edit?_id=${p._id}`)}>Delete</Button> }
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      
    </div>
  );
}

export default Pages;
